<template>
  <div
    class="modal fade"
    id="addBlogModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="addBlogModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addBlogModalLabel">Add News</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-left">
          <form @submit.prevent="saveBlog">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="blogHeading">Heading</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="blogHeading"
                    v-model="blogFormBody.blogHeading"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="blogSubHeading">Sub-Heading</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="blogSubHeading"
                    v-model="blogFormBody.blogSubHeading"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group form-group-sm">
                  <label for="blogType">Access Type</label>
                  <select
                    class="form-control form-control-sm"
                    id="blogType"
                    v-model="blogFormBody.blogType"
                  >
                    <option
                      v-for="blogType in blogTypes"
                      :key="blogType.gpId"
                      :value="blogType.gpId"
                    >
                      {{ blogType.shortDescription }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group form-group-sm">
                  <label for="accessType">Blog Type</label>
                  <select
                    class="form-control form-control-sm"
                    id="accessType"
                    v-model="blogFormBody.accessType"
                  >
                    <option
                      v-for="access in accessTypes"
                      :key="access.gpId"
                      :value="access.gpId"
                    >
                      {{ access.shortDescription }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="imageFile">Image File</label>
                  <input
                    @change="convertImageToBase64($event)"
                    type="file"
                    class="form-control-file"
                    id="imageFile"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="blogParagraph1">Paragraph 1</label>
                  <textarea
                    class="form-control"
                    id="blogParagraph1"
                    rows="3"
                    v-model="blogFormBody.blogParagraph1"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="blogParagraph2">Paragraph 2</label>
                  <textarea
                    class="form-control"
                    id="blogParagraph2"
                    rows="3"
                    v-model="blogFormBody.blogParagraph2"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="blogParagraph3">Paragraph 3</label>
                  <textarea
                    class="form-control form-control-sm"
                    id="blogParagraph3"
                    rows="3"
                    v-model="blogFormBody.blogParagraph3"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="blogConclusion">Conclusiuon</label>
                  <textarea
                    class="form-control form-control-sm"
                    id="blogConclusion"
                    rows="3"
                    v-model="blogFormBody.blogConclusion"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="publishedBy">Published By</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="publishedBy"
                    v-model="blogFormBody.publishedBy"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="publisherName">Publisher Name</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="publisherName"
                    v-model="blogFormBody.publisherName"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="publishDate">Publisher Date</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    id="publishDate"
                    v-model="blogFormBody.publishDate"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="blogLink">Blog Link</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="blogLink"
                    v-model="blogFormBody.blogLink"
                  />
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
// var base64Img = require('base64-img');
import $ from "jquery";

export default {
  name: "AddBlog",
  props: ["recordToUpdate"],
  data() {
    return {
      blogFormBody: null,
      blogTypes: [],
      accessTypes: [],
    };
  },
  watch: {
    recordToUpdate: function (newData) {
      this.blogFormBody = newData;
    },
  },
  created() {
    this.getAccessTypes();
    this.getBlogTypes();

    if (this.recordToUpdate) {
      this.blogFormBody = this.recordToUpdate;
    } else {
      this.refreshForm();
    }
  },
  mounted() {
    $("#addBlogModal").on("hidden.bs.modal", ()=> {
      this.refreshForm();
    });
  },
  methods: {
    refreshForm() {
      this.blogFormBody = {
        blogId: 0,
        blogType: 0,
        blogHeading: "",
        blogSubHeading: "",
        blogParagraph1: "",
        blogParagraph2: "",
        blogParagraph3: "",
        blogConclusion: "",
        blogLink:"",
        imageFile: "",
        accessType: 0,
        publisherName: "",
        publishedBy: "",
      };
    },
    saveBlog() {
      var _this = this;
      axios
        .post("/api/Blog/SaveBlog", this.blogFormBody)
        .then(function (response) {
          alert(response.data);
          $("#addBlogModal").modal("toggle");
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          } else {
            console.log(error);
          }
        });
    },

    getAccessTypes() {
      var _this = this;
      const emptyObj = {
        gpType: "Blog Type",
      };
      axios
        .post(
          window.location.origin + "/api/GeneralPurpose/GPByGPType",
          emptyObj,
          {
            withCredentials: true,
          }
        )
        .then(function (resp) {
          _this.accessTypes = resp.data.generalPurposeItems;
        })
        .catch(function (error) {
          if (error.response) {
            alert("Something went wrong.");
          } else {
            console.log(error);
          }
        });
    },

    getBlogTypes() {
      var _this = this;
      const emptyObj = {
        gpType: "Access Type",
      };
      axios
        .post(
          window.location.origin + "/api/GeneralPurpose/GPByGPType",
          emptyObj,
          {
            withCredentials: true,
          }
        )
        .then(function (resp) {
          _this.blogTypes = resp.data.generalPurposeItems;
        })
        .catch(function (error) {
          if (error.response) {
            alert("Something went wrong.");
          } else {
            console.log(error);
          }
        });
    },
    convertImageToBase64(event) {
      let rawImg;
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        rawImg = reader.result;
        this.blogFormBody.imageFile = rawImg;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>
  
  <style>
</style>