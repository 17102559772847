<template>
  <div v-if="news" id="NewsView" class="container-fluid news-view">
    <!-- Heading -->
    <div class="row text-center">
      <h5>{{ news.blogHeading }}</h5>
    </div>
    <!-- News Details -->

    <div class="row text-secondary border-top border-bottom mt-3 mb-3 pt-3">
      <div class="col-md text-md-left">
        <p>
          Posted by {{ news.creatorName }}
          {{ "(" + countPostTime(news.publishDate) + ")" }}
        </p>
      </div>
      <div class="col-md text-md-right">
        <p>
          Published
          <span v-if="news.publisherName.length != 0">
            by {{ news.publisherName }}</span
          >
          <span v-if="news.publishedBy.length != 0"
            >in {{ news.publishedBy }}</span
          >
        </p>
      </div>
    </div>

    <!-- News Image -->
    <div class="row text-center fill">
      <img
        :src="news.imageFile"
        height="250"
        width="100"
        alt="image"
        class="img-fluid"
      />
    </div>
    <!-- Paragraph 1 -->
    <div class="row text-left mt-3">
      <p>{{ news.blogParagraph1 }}</p>
    </div>
    <!-- Paragraph 2 -->
    <div class="row text-left">
      <p>{{ news.blogParagraph2 }}</p>
    </div>
    <!-- Paragraph 3 -->
    <div class="row text-left">
      <p>{{ news.blogParagraph3 }}</p>
    </div>
    <!-- Conclusion -->
    <div class="row text-left">
      <p>{{ news.conclusion }}</p>
    </div>
    <!-- Details  -->
    <div class="row text-left"></div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "NewsView",
  props: ["newsDetails"],
  data() {
    return {
      news: null,
    };
  },
  watch: {
    newsDetails: function (newData, oldData) {
      this.news = newData;
    },
  },
  created() {
    this.news = this.newsDetails;
  },
  mounted() {},
  methods: {
    countPostTime(dateTime) {
      return moment(dateTime).format("Do MMMM YYYY");
    },
  },
};
</script>

<style>
.news-view .fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.news-view .fill img {
  flex-shrink: 0;
  min-width: 50%;
  min-height: 100%;
}
</style>