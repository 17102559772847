<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light fixed-top">
      <div
        id="phoneViewNotificationScroller"
        class="row"
        style="width: 100%; height: 2rem; margin: 0px !important"
      >
        <!-- <div class="col-2">
          <button class="btn btn-light"><i class="fas fa-circle-info"></i></button>
        </div> -->
        <!-- <div class="col-2">
          <div
            id="single-normal"
            class="pb-1"
            style="text-align: left"
            tooltip="Add Event To Calendar"
          ></div>
        </div> -->
        <div class="col-12">
          <div class="scroll-left">
            <p style="white-space: nowrap">
              Upcoming Event:
              <i
                ><b>
                  <span v-if="upcomingEvent==null" class="eventNotification">
                
                      No Upcoming Events
               
                    </span
                  ></b
                >

                <b>
                  <router-link v-if="(upcomingEvent!=null && upcomingEvent.length!=0)" class="eventNotification" to="/events/UpcomingEvents">
                    <span v-if="upcomingEvent.length!=0">
                      {{upcomingEvent[0].title}}
                    </span>
                   </router-link
                  ></b
                >
              
              </i>
            </p>
          </div>
        </div>
      </div>
      <div class="row m-0" style="width: 100%; margin: 0px !important">
        <div class="col-lg-2 align-self-center" style="text-align: left">
          <div class="row p-1 m-0" style="width: 100%">
            <button
              class="navbar-toggler ml-auto col-2 align-self-center"
              style="height: 100%; text-align: left;max-width: fit-content;"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="col p-0">
              <router-link to="/">
                <img dir="small"
                  class="logoImage"
                  src="../../assets/Logo/image.webp"
                  alt=""
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md align-self-center" style="padding-right: 0px">
          <div
            class="row d-none d-lg-block d-xl-block m-0"
            style="width: 100%; text-align: left; margin: 0px !important"
          >
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav" style="width: 100%">
                <li class="nav-item">
                  <a class="nav-link" style="color: #3b5aa7"
                    ><i class="fas fa-bell"></i
                  ></a>
                </li>
                <!-- <li class="nav-item mr-5">
                  <div class="nav-link search-container">
                    <form action="/search" method="get">
                      <input
                        class="search expandright"
                        id="searchright"
                        type="search"
                        name="q"
                        placeholder="Search"
                      />
                      <label class="button searchbutton" for="searchright"
                        ><span class="mglass"><i class="fas fa-search"></i></span
                      ></label>
                    </form>
                  </div>
                </li> -->

                <li class="nav-item">
                  <p class="p-0 mb-0 mt-2">
                    Upcoming Event:
                    <i>
                      <b>
                  <span v-if="upcomingEvent==null" class="eventNotification">
                    No Upcoming Events</span
                  ></b
                >

                <b>
                  <router-link v-if="upcomingEvent!=null" class="eventNotification" to="/events/UpcomingEvents">
                    <span v-if="upcomingEvent.length!=0">
                      {{upcomingEvent[0].title}}
                    </span>
                    <span v-else>No Upcoming Events</span>

                    </router-link
                  ></b
                >
                    </i>
                  </p>
                </li>

                <li
                  id="calendarLinks"
                  class="ml-2 mt-2"
                  style="text-align: left"
                  tooltip="Add Event To Calendar"
                ></li>
                <!-- 
                </li>

               
                <marquee id="blink" class="mt-2" behavior="scroll" direction="up" scrollamount="1"
                  >
                  <p>Here is some scrolling text... right to left!</p> 
                  </marquee
                > -->
              </ul>
            </div>
          </div>
          <div
            class="row m-0"
            id="downNav"
            style="width: 100%; text-align: left; margin: 0px !important"
          >
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav">
                <li class="nav-item active mt-0 clickable">
                  <router-link
                    class="nav-link"
                    to="/"
                    style="border-radius: 0px"
                    >Home
                  </router-link>
                </li>

                <li class="nav-item dropdown mt-0">
                  <a
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style="border-radius: 0px"
                    href=""
                    >About Us</a
                  >
                  <div
                    class="dropdown-menu customDropdownMenu pl-2 pr-2 pb-1 pt-0"
                    aria-labelledby="navbarDropdown"
                  >
                    <router-link
                      class="
                        dropdown-item dropdown-item-custom
                        mt-0
                        pt-0
                        clickable
                      "
                      to="/about"
                      >About the SPNM</router-link
                    >
                    <div class="dropdown-divider"></div>
                    <router-link
                      class="dropdown-item dropdown-item-custom clickable"
                      to="/members/board"
                      >Board</router-link
                    >
                    <div class="dropdown-divider"></div>
                    <router-link
                      class="dropdown-item dropdown-item-custom clickable"
                      to="/members/supporting"
                      >Supporting Members
                    </router-link>
                  </div>
                </li>
                <li class="nav-item dropdown mt-0">
                  <a
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style="border-radius: 0px"
                    href=""
                  >
                    Events
                  </a>
                  <div
                    class="dropdown-menu customDropdownMenu pl-2 pr-2 pb-1 pt-0"
                    aria-labelledby="navbarDropdown"
                  >
                    <router-link
                      class="
                        dropdown-item dropdown-item-custom
                        mt-0
                        pt-0
                        clickable
                      "
                      to="/events/UpcomingEvents"
                      >Upcoming Events</router-link
                    >
                    <div class="dropdown-divider"></div>
                    <router-link
                      class="dropdown-item dropdown-item-custom"
                      to="/events/PastEvents"
                      >Past Events</router-link
                    >
                  </div>
                </li>
                <!-- <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Media
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <router-link class="dropdown-item" to="/media/gallary">Gallary</router-link>
                    <div class="dropdown-divider"></div>
                    <router-link class="dropdown-item" to="/media/press">In the Press</router-link>
                  </div>
                </li> -->
                <li class="nav-item dropdown mt-0">
                  <router-link
                    class="nav-link clickable"
                    to="/membership"
                    style="border-radius: 0px"
                  >
                    Membership
                  </router-link>
                </li>
                <li class="nav-item dropdown mt-0">
                  <a
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style="border-radius: 0px"
                    href=""
                  >
                    Contributors
                  </a>
                  <div
                    class="dropdown-menu customDropdownMenu pl-2 pr-2 pb-1 pt-0"
                    aria-labelledby="navbarDropdown"
                  >
                    <router-link
                      class="
                        dropdown-item dropdown-item-custom
                        mt-0
                        pt-0
                        clickable
                      "
                      to="/contributors-organisations"
                      >Contributing Organizations</router-link
                    >
                    <div class="dropdown-divider"></div>
                    <router-link
                      class="dropdown-item dropdown-item-custom clickable"
                      to="/contributors-partners"
                      >Partners</router-link
                    >
                    <!-- <div class="dropdown-divider"></div> -->
                    <!-- <router-link
                      class="dropdown-item"
                      to="/contributors-sponsors"
                      >Become a Sponsor
                    </router-link> -->
                  </div>
                </li>
                <li class="nav-item dropdown mt-0">
                  <router-link
                    class="nav-link clickable"
                    to="/news"
                    style="border-radius: 0px"
                  >
                   News
                  </router-link>
                </li>
                <li class="nav-item dropdown mt-0">
                  <router-link
                    class="nav-link clickable"
                    to="/affiliates"
                    style="border-radius: 0px"
                  >
                    SPN Groups
                  </router-link>
                </li>
              </ul>

              <ul class="navbar-nav ml-auto">
                <li v-if="userInfo==null" class="nav-item mt-0">
                  <a
                    class="nav-link clickable"
                    data-toggle="modal"
                    data-target="#loginModal"
                    href=""
                    ><i class="fa fa-user fa-sm mr-1"></i>Login/Register</a
                  >
                </li>

                <li v-if="loggedIn" class="nav-item dropdown mt-0">
                  <a
                    class="nav-link clickable"
                    data-toggle="modal"
                    data-target="#loginModal"
                    href=""
                    > <small>Membership Status:  <span class="badge badge-light">{{userInfo.membershipStatus}}</span></small> </a
                  >
                </li>

                <li v-if="userInfo!=null" class="nav-item dropdown mt-0">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ userInfo.firstName }} {{ userInfo.lastName }}
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="navbarDropdown"
                  >
                  <router-link
                    class="dropdown-item text-dark"
                    to="/profile"
                    style="border-radius: 0px"
                  >
                    Profile
                  </router-link>
                    <div class="dropdown-divider"></div>
                    <a @click="logout()" class="dropdown-item" href=""
                      >Logout</a
                    >
                  </div>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="#"></a>
                </li>
              </ul>
              <!-- <form class="form-inline my-2 my-lg-0">
              <input
                class="form-control mr-sm-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button class="btn btn-outline-success my-2 my-sm-0" type="submit">
                Search
              </button>
            </form> -->
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- Modal -->
    <!-- <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" id="single-normal"></div>
          
        </div>
      </div>
    </div> -->
    <LoginModal />
  </div>
</template>

<script>
import $ from "jquery";
import LoginModal from "../Auth/Login.vue";
import * as Add2Calendar from "add2calendar";
import * as getLogout from "../../GeneralScripts/Logout";
export default {
  name: "Navbar",
  components: {
    LoginModal,
  },
  data() {
    return {
      userInfo: null,
      loggedIn: false,
      upcomingEvent:[]
    };
  },

  watch: {
    watchUserInfo: function (newData, oldData) {
      this.userInfo = newData
      // this.gridStackEditMode();
   
    },
    watchUpcomingEvent: function (newData, oldData) {
      this.upcomingEvent = newData
      // this.gridStackEditMode();
    }
  },

   computed: {
    watchUserInfo() {
      return this.$store.getters.LoggedUserInfo;
    },
    watchUpcomingEvent() {
      return this.$store.getters.UpcomingEvent;
    },
  },

  created() {
    this.userInfo = this.$store.getters.LoggedUserInfo;
    if(this.userInfo){
  
    }
    this.upcomingEvent = this.$store.getters.UpcomingEvent;
  },
  mounted() {
 
    // this.marqueePlay();
    // var blink = document.getElementById("blink");
    // setInterval(function () {
    //   blink.style.opacity = blink.style.opacity == 0 ? 1 : 0;
    // }, 500);

    // $(".routerSection").click(function () {
    //   if ($('.a2cldr').hasClass('active')){
    //     $(".a2cldr ").removeClass("active");
    //   }

    // });

    $(".clickable").click(function () {
      $(".dropdown-menu").removeClass("parentDiv");
      $(".nav-item").removeClass("parentActive");
      $(".navbar-toggler").addClass("collapsed");
      $(".navbar-toggler").attr("aria-expanded", "false");
      $(".navbar-collapse").removeClass("show");
      $(this).parent().addClass("parentDiv");
      $(".dropdown-menu.parentDiv").parent().addClass("parentActive");
    });

    $(document).ready(function () {
      $(document).click(function (event) {
        var clickover = $(event.target);
        var _opened = $(".navbar-collapse").hasClass("navbar-collapse in");
        if (_opened === true && !clickover.hasClass("navbar-toggle")) {
          $("button.navbar-toggle").click();
        }
      });
    });

    // this.addEvent();
  },

  methods: {
    logout() {
      getLogout.Logout()
    },
    homePage() {
      window.location.href = window.location.origin;
      // window.location.origin.reload()
    },
    marqueePlay() {
      $("#marquee").animate(
        {
          left: $(window).width() - $("#marquee").width(),
          opacity: 0,
        },
        20000,
        function () {
          $("#marquee").css("left", 0);
          $("#marquee").css("opacity", 1);
          marqueePlay();
        }
      );
    },
    addEvent() {
      // $("#exampleModal").modal("show");
      var singleEventArgs = {
        title: "KNOWLEDGE SESSION 2022",
        start: "June 11, 2022 09:00",
        end: "June 11, 2022 15:00",
        location:
          "Mirage Hotel, Andheri-Kurla Rd, Navpada, Marol, Andheri East, Mumbai – 400059.",
        description:
          "The Shipping Professional Network in Mumbai (SPNM) is delighted to invite you to its Knowledge Session on 11 June 2022 at 0900hrs IST, ending with a networking lunch." +
          "The half day event will be held in suburban  Mumbai at Mirage Hotel, Andheri East." +
          "The event will focus on Shipping documentation process and information regarding export and import  activities. " +
          "Vaishali Kale, Documentation Head, Parekh Group, will take us through this essential and interesting  topic. " +
          "You will get a chance to clear your doubts and also provide your words of wisdom. " +
          "Are you ready with  your questions as well as points for Vaishali and the participants? ",
        isAllDay: false,
      };
      var singleEvent = new Add2Calendar(singleEventArgs);

      // to get actual url
      singleEvent.getGoogleUrl(); // https://www.google.com/calendar/render?action=TEMPLATE&text=...

      // render a widget
      singleEvent.createWidget("#single-normal");
      singleEvent.createWidget("#calendarLinks");

      $(".a2cldr-btn").html(
        "<i class='far fa-calendar-plus fa-lg navbarCalendar'></i>"
      );

      $(".icon-google").html("<i class='fab fa-google fa-lg'></i> Google");

      $(".icon-ical").html(
        "<i class='far fa-calendar fa-lg'></i> Download ICS"
      );
    },
  },
};
</script>

<style>
.a2cldr-item.a2cldr-outlook,
.a2cldr-item.a2cldr-yahoo {
  display: none;
}

.logoImage {
  height: 8rem;
  width: 14rem;
}

.navbar {
  background-color: #fff;
  padding: 0rem !important;
  font-size: 0.9rem;
}
#downNav {
  background-color: #3b5aa7;
  /* background-image: url("../../assets/Logo/navbackground.webp"); */
  color: #fff !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  /* border-bottom: 3px solid red; */
}
#downNav .nav-link {
  color: #fff !important;
}
marquee {
  /* color: #a1a1a1; */
  background-color: #fff;
}
.navbar-toggler {
  height: max-content;
}

.button {
  display: inline-block;

  height: 50%;
  line-height: 100%;
  text-align: center;
  color: rgb(168, 166, 166);
  text-decoration: none;
  cursor: pointer;
}

.button:hover {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: white;
  color: black;
}

.search-container {
  position: relative;
  display: inline-block;
  height: 45px;
  vertical-align: bottom;
}

.searchbutton {
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;
}

.search:focus + .searchbutton {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: white;
  color: black;
}

.search {
  position: absolute;

  background-color: white;
  outline: none;
  border: none;
  padding: 0;
  width: 0;
  height: 50%;
  border: 0px solid rgb(168, 166, 166);
  border-radius: 0px;
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
}

.search:focus {
  width: 363px; /* Bar width+1px */
  padding: 0 16px 0 0;
}

.expandright {
  left: auto;
  right: 10px; /* Button width-1px */
}

.expandright:focus {
  padding: 0 0 0 16px;
}

.search-container input {
  border: 2px solid rgb(168, 166, 166);
  border-radius: 50px;
}

#blink {
  color: #000000;
}

.customDropdownMenu {
  border-top: 0.5px solid #fff;
  padding: 0px;
  margin: 0px;
  border: 0px;
  background-color: #3b5aa7;
  color: #fff !important;
  border-radius: 0px;
  font-size: 0.9rem;
}

.dropdown-divider {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #707070;
}
.dropdown-item-custom {
  color: #fff;
  font-weight: 350;
  padding-left: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.customDropdownMenu a:hover {
  /* color: rgba(255, 0, 0, 1);
  background-color: #3b5aa7 !important; */
  background-color: rgba(255, 255, 255, 0.247);

  color: #fff;
}

#navLink {
  /* max-height: max-content; */
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  /* background-color: ; */
  margin-top: 8px;
  margin-bottom: 8px;
  border-right: 1px solid rgb(119, 119, 119);
}

#downNav .router-link-active,
.parentActive,
#downNav .show .dropdown-toggle {
  /* color: rgb(255, 0, 0) !important; */
  background-color: rgba(255, 255, 255, 0.247);
  /* border-radius: 10px; */
  color: #fff;
}

.nav-link.dropdown-toggle.active {
  background-color: rgba(255, 255, 255, 0.247);
  /* border-radius: 10px; */
  color: #fff;
}

/* Calender CSS */

.a2cldr {
  height: 30px;

  position: relative;

  font-family: sans-serif;
}

.navbarCalendar {
  /* color: #3b5aa7; */
}
.a2cldr .a2cldr-btn {
  /* width: 246px;
  height: 46px;
  display: inline-block; */
  /* margin-left: 5px; */
  border: 0px solid #3b5aa7;
  /* padding: 8px 18px; */
  text-align: left;
  cursor: pointer;
  /*
    -webkit-transition: all .2s ease-in-out;
       -moz-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
         -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
    */
  box-sizing: border-box;
  /* reset default agent style */
  font: inherit;
  color: inherit;
  background: inherit;
}
.a2cldr .a2cldr-btn:before {
  display: inline-block;
  float: right;
  position: absolute;
  top: 0;
  right: 16px;
  font-size: 24px;
  line-height: 30px;
}
.a2cldr.active .a2cldr-btn {
  color: #fff;
  background-color: #3b5aa7;
}
.a2cldr.active .a2cldr-btn:before {
  font-size: 20px;
  line-height: 44px;
}
.a2cldr.active .a2cldr-list {
  display: block;
}
.a2cldr-list {
  margin-left: 5px;
  position: fixed;
  z-index: 2;
  padding: 0 12px;
  display: none;
  background-color: #fff;
  box-shadow: 0px 8px 20px 0px #bababa;
}
.a2cldr-item {
  list-style: none;
  padding: 5px 0;
  border-bottom: 1px solid #f3f3f3;
}
.a2cldr-item a {
  color: #000000;
  text-decoration: none;
  width: 100%;
  display: block;
}
.a2cldr-item a:hover {
  color: #3b5aa7;
  text-decoration: underline;
}
.a2cldr-item:last-child {
  border-bottom: 0;
}

.eventNotification {
  color: #3b5aa7;
}

.scroll-left {
  height: 20px;
  overflow: hidden;
  position: relative;

  color: black;
}
.scroll-left p {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;

  text-align: center;

  transform: translateX(100%);

  animation: scroll-left 10s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

#phoneViewNotificationScroller {
  display: none;
}

@media only screen and (max-width: 992px) {
  #phoneViewNotificationScroller {
    display: flex;
  }
}

@media only screen and (max-width: 1350px) {
  .logoImage {
    height: 7rem;
    width: 12rem;
  }
}

@media only screen and (max-width: 1150px) {
  .logoImage {
    height: 6rem;
    width: 10rem;
  }
}

@media only screen and (max-width: 992px) {
  .logoImage {
    height: 5rem;
    width: 9rem;
  }
  #downNav .router-link-active,
  .parentActive,
  #downNav .show .dropdown-toggle {
    /* color: rgb(255, 0, 0) !important; */
    background-color: rgba(255, 255, 255, 0.247);
    /* border-radius: 10px; */
    color: #3b5aa7;
  }
  .customDropdownMenu {
    background-color: #fff !important;
    color: #000000 !important;
  }

  .nav-link.dropdown-toggle.active {
    background-color: rgba(255, 255, 255, 0.247);
    /* border-radius: 10px; */
    color: #3b5aa7;
  }

  .dropdown-item-custom {
    color: #000000;
  }
  .carousel-inner {
    height: 20vh;
  }
  .navbar-nav {
    margin-right: 20px;
  }
  #downNav {
    background-color: #ffffff;
    color: rgb(0, 0, 0) !important;
    /* border-bottom: 3px solid rgb(212, 212, 212); */
  }
  #downNav .nav-link {
    color: rgb(0, 0, 0) !important;
  }
  marquee {
    display: none;
  }
  #navLink {
    /* max-height: max-content; */
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    /* background-color: ; */
    margin-top: 8px;
    margin-bottom: 8px;
    border-right: 0px solid rgb(119, 119, 119) !important;
  }
}
</style>




