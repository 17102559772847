<template>
  <div class="container-fluid">
    <nav
      class="navbar navbar-expand-lg navbar-light bg-light adminNavbar"
      style="padding: 0.5rem !important"
    >
      <a class="navbar-brand" href="#"
        ><i class="fas fa-user-gear"></i> Admin Panel</a
      >
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li v-if="userInfo != null" class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ userInfo.firstName }} {{ userInfo.lastName }}
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
            >
              <a @click="logout()" class="dropdown-item" href="">Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <div class="row h-100">
      <div class="col-md-2 h-100">
        <div class="bg-white mt-3 p-2" style="border-radius: 1rem">
          <div class="sidebarItem mt-1">
            <p @click="switchComponent('UserList')">Users</p>
            <hr class="mt-1 mb-1" />
          </div>

          <div @click="switchComponent('EventList')" class="sidebarItem mt-1">
            <p>Events</p>
            <hr class="mt-1 mb-1" />
          </div>

          <div @click="switchComponent('MailingList')" class="sidebarItem mt-1">
            <p>Mailing List</p>
            <hr class="mt-1 mb-1" />
          </div>

          <div
            @click="switchComponent('NewsAdminList')"
            class="sidebarItem mt-1"
          >
            <p>News</p>
            <hr class="mt-1 mb-1" />
          </div>

          <div @click="switchComponent('Profile')" class="sidebarItem mt-1">
            <p>Profile</p>
          </div>
        </div>
      </div>
      <div class="col-md-10 pr-0">
        <div
          class="bg-white mt-3 p-3 custom-scroller"
          style="max-height: 87vh; border-radius: 1rem"
        >
          <component :is="componentId"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserList from "../components/Admin/UserList.vue";
import EventList from "../components/Admin/EventList.vue";
import ChangePassword from "../components/UserProfile/ChangePassword.vue";
import Profile from "./Profile.vue";
import MailingList from "../components/Admin/MailingList.vue";
import NewsAdminList from "../components/Admin/NewsList.vue";
import axios from "axios";
import * as getLogout from "../GeneralScripts/Logout";
export default {
  name: "AdminPanel",
  components: {
    UserList,
    EventList,
    ChangePassword,
    Profile,
    MailingList,
    NewsAdminList,
  },
  data() {
    return {
      userInfo: null,
      componentId: "UserList",
    };
  },
  watch: {
    watchUserInfo: function (newData, oldData) {
      this.userInfo = newData;

      // this.gridStackEditMode();
    },
  },

  computed: {
    watchUserInfo() {
      return this.$store.getters.LoggedUserInfo;
    },
  },
  created() {
    this.userInfo = this.$store.getters.LoggedUserInfo;
  },
  methods: {
    switchComponent(compo) {
      this.componentId = compo;
    },
    logout() {
      getLogout.Logout();
    },
  },
};
</script>

<style >
.sidebarItem {
  cursor: pointer;
}
.sidebarItem p {
  margin-bottom: 0px;
}

.fa-paper-plane {
  cursor: pointer;
}

/* .adminNavbar{
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
} */
</style>