// import * as $ from "jquery";
import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import 'jquery-ui';
require('jquery')
require('bootstrap')
// require('admin-lte')

require("datatables.net-dt")
require('jszip')();
require('datatables.net-bs4');
require('datatables.net-autofill-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons-dt');
require('datatables.net-buttons/js/buttons.colVis.js');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-colreorder-bs4');
require('datatables.net-datetime');
require('datatables.net-fixedcolumns-bs4');
require('datatables.net-fixedheader-bs4');
require('datatables.net-keytable-bs4');
require('datatables.net-responsive-bs4');
require('datatables.net-rowgroup-bs4');
require('datatables.net-rowreorder-bs4');
require('datatables.net-scroller-bs4');
require('datatables.net-searchbuilder-bs4');
require('datatables.net-searchpanes-bs4');
require('datatables.net-select-bs4');
// require( 'datatables.net-staterestore-bs4' );
require('loaderjs');


createApp(App).use(store).use(router).mount('#app')
