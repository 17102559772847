<template>
  <div
    class="modal fade"
    id="addMailingRecordFormModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="addMailingRecordFormModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addMailingRecordFormModalLabel">
            Mailing Record
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="text-left" @submit.prevent="saveMailingRecord()">
            <div class="form-row text-left">
              <div class="form-group form-group-sm col-md-6">
                <label for="firstName">First Name</label>
                <input
                  v-model="mailerRecordBody.firstName"
                  type="text"
                  class="form-control form-control-sm"
                  id="firstName"
                  maxlength="50"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="lastName">Last Name</label>
                <input
                  v-model="mailerRecordBody.lastName"
                  type="text"
                  class="form-control form-control-sm"
                  id="lastName"
                  maxlength="50"
                  required
                />
              </div>
            </div>
            <!-- <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="username">Username</label>
                  <input v-model="mailerRecordBody.userName" type="text" class="form-control" id="username" />
                </div>
                <div class="form-group col-md-6">
                  <label for="password">Password</label>
                  <input v-model="mailerRecordBody.password" type="password" class="form-control" id="password" />
                </div>
              </div> -->
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="personalEmail">Personal Email</label>
                <input
                  v-model="mailerRecordBody.personalEmailId"
                  type="email"
                  class="form-control form-control-sm"
                  id="personalEmail"
                  maxlength="254"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="officalEmail">Official Email</label>
                <input
                  v-model="mailerRecordBody.officialEmailId"
                  type="email"
                  class="form-control form-control-sm"
                  id="officalEmail"
                  maxlength="254"
                  required
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="gender">Gender</label>
                <select
                  v-model="mailerRecordBody.gender"
                  id="gender"
                  class="form-control form-control-sm"
                  maxlength="50"
                  required
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label for="birthDate">Birth Date </label>
                <input
                  v-model="mailerRecordBody.birthDate"
                  type="date"
                  class="form-control form-control-sm"
                  id="birthDate"
                  required
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="education">Highest Education</label>
                <input
                  v-model="mailerRecordBody.highestEducation"
                  type="text"
                  class="form-control form-control-sm"
                  id="education"
                  maxlength="254"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="industryVertical">Industry/Vertical</label>
                <input
                  v-model="mailerRecordBody.industryVertical"
                  type="text"
                  class="form-control form-control-sm"
                  id="education"
                  maxlength="254"
                  required
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="companyName">Company Name</label>
                <input
                  v-model="mailerRecordBody.companyName"
                  type="text"
                  class="form-control form-control-sm"
                  id="companyName"
                  maxlength="254"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="jobTitle">Job Title/Position</label>
                <input
                  v-model="mailerRecordBody.jobTitle"
                  type="text"
                  class="form-control form-control-sm"
                  id="jobTitle"
                  maxlength="254"
                  required
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="personalMobileNumber">Personal Mobile No.</label>
                <input
                  v-model="mailerRecordBody.personalMobileNo"
                  type="text"
                  class="form-control form-control-sm"
                  id="personalMobileNumber"
                  maxlength="13"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="alternativeMobileNumber"
                  >Alternative Mobile No.</label
                >
                <input
                  v-model="mailerRecordBody.alternativeMobileNo"
                  type="text"
                  class="form-control form-control-sm"
                  id="alternativeMobileNumber"
                  maxlength="13"
                  required
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md">
                <label for="address">Preffered Address</label>
                <textarea
                  v-model="mailerRecordBody.address"
                  class="form-control form-control-sm"
                  id="address"
                  rows="2"
                  maxlength="999"
                  required
                ></textarea>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="subscribed"
                    v-model="mailerRecordBody.subscribed"
                    true-value="Y"
                    false-value="N"
                    disabled
                  />
                  <label class="form-check-label" for="subscribed">
                    Subscribed
                  </label>
                </div>
              </div>
              <div class="form-group col-md-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="member"
                    v-model="mailerRecordBody.isMember"
                    true-value="Y"
                    false-value="N"
                  />
                  <label class="form-check-label" for="member">
                    Member
                  </label>
                </div>
              </div>
              <div class="form-group col-md-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="active"
                    v-model="mailerRecordBody.active"
                    true-value="Y"
                    false-value="N"
                  />
                  <label class="form-check-label" for="active">
                    Active
                  </label>
                </div>
              </div>
            </div>

            <div class="form-row mb-3 justify-content-center">
              <button type="submit" class="btn btn-spnm btn-sm">Save</button>
            </div>
          </form>
          <div v-if="loading" class="overlay">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import moment from "moment";
import $ from "jquery";

export default {
  name: "AddMailerRecordForm",
  data() {
    return {
      industryVerticals: [],
      mailerRecordPlans: [],
      userInfo: null,
      loading: false,
      mailerRecordBody: {
        mailerListId: 0,
        firstName: null,
        lastName: null,
        personalEmailId: null,
        officialEmailId: null,
        gender: null,
        birthDate: null,
        highestEducation: null,
        industryVertical: null,
        companyName: null,
        jobTitle: null,
        personalMobileNo: null,
        alternativeMobileNo: null,
        address: null,
        subscribed: "Y",
        isMember: "N",
        active: "Y",
      },
    };
  },
  watch: {
    watchUserInfo: function (newData, oldData) {
      this.userInfo = newData;

      if (this.userInfo != null) {
        if (!this.$store.getters.IsAdmin) {
          this.mailerRecordBody = this.userInfo;
          this.mailerRecordBody.birthDate = moment(
            this.mailerRecordBody.birthDate
          ).format("yyyy-MM-DD");
        }
      }
    },
  },
  computed: {
    watchUserInfo() {
      return this.$store.getters.LoggedUserInfo;
    },
  },

  created() {
    this.userInfo = this.$store.getters.LoggedUserInfo;

    if (
      this.userInfo != null &&
      this.userInfo.mailerRecordStatus == "InActive"
    ) {
      if (!this.$store.getters.IsAdmin) {
        this.mailerRecordBody = this.userInfo;
        this.mailerRecordBody.birthDate = moment(
          this.mailerRecordBody.birthDate
        ).format("yyyy-MM-DD");
      }
    }
  },

  methods: {
    resetForm() {
      if (this.$store.getters.LoggedUserInfo) {
        return null;
      }
      this.mailerRecordBody = {
        mailerListId: 0,
        firstName: null,
        lastName: null,
        personalEmailId: null,
        officialEmailId: null,
        gender: null,
        birthDate: null,
        highestEducation: null,
        industryVertical: null,
        companyName: null,
        jobTitle: null,
        personalMobileNo: null,
        alternativeMobileNo: null,
        address: null,
        subscribed: "Y",
        isMember: "N",
        active: "Y",
      };
    },
    saveMailingRecord() {
      var _this = this;

      this.loading = true;

      console.log(this.mailerRecordBody);

        axios
          .post("/api/MailingListSource/Register", this.mailerRecordBody)
          .then(function () {
            alert("Record Saved.")
            _this.loading = false;
            $("#addMailingRecordFormModal").modal("toggle")
          })
          .catch(function (error) {
            _this.loading = false;
            if (error.response) {
              alert(error.response.data[0]);
            } else {
              console.log(error);
            }
          });
    },
  },
};
</script>
  
  <style>
</style>