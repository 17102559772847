<template>
  <div id="event-list" class="">
    <div class="row justify-content-center">
      <h5 class="ml-auto mr-auto">News</h5>
      <button
        type="button"
        class="btn btn-light btn-sm"
        data-toggle="modal"
        data-target="#addBlogModal"
      >
        Add News <i class="fa-solid fa-plus ml-2"></i>
      </button>
    </div>

    <hr />
    <div v-if="!loading" class="list p-1 text-left">
      <div
        v-for="news in newsCollection"
        :key="news.blogId"
        class="card p-3 m-1"
      >
        <div class="row">
          <div class="col-md-3 text-center fill">
            <img
              :src=" news.imageFile"
              height="100"
              width="100"
              alt="No Image"
              class="img-thumbnail"
            />
          </div>
          <div class="col-md-8">
            <h4>{{ news.blogHeading }}</h4>
            <p>{{ news.blogSubHeading }}</p>
            <div class="row text-secondary">
              <div class="col-md-6">
                Posted by {{ news.creatorName }}
                {{ "(" + countPostTime(news.publishDate) + ")" }}
              </div>
              <div
                class="col-md-6"
                v-if="
                  news.publisherName != null || news.publisherName.length != 0
                "
              >
                <p>
                  Published <span v-if="news.publisherName.length!=0"> by {{ news.publisherName }}</span> <span v-if="news.publishedBy.length!=0">in
                  {{ news.publishedBy }}</span> 
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-1 text-center align-self-center">
            <div
              class="btn-group btn-group-sm"
              role="group"
              aria-label="Basic example"
            >
              <button
                @click="viewNews(news)"
                type="button"
                class="btn btn-light"
                data-toggle="modal"
                data-target="#viewNewsModal"
              >
                <i class="fas fa-eye"></i>
              </button>
              <button
                @click="updateRecord(news)"
                type="button"
                class="btn btn-light"
              >
                <i class="fas fa-edit text-primary"></i>
              </button>
              <button
                @click="deleteEvent(news.blogId)"
                type="button"
                class="btn btn-light"
              >
                <i class="fas fa-trash-alt text-danger"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" style="height: 50vh">
      <div class="overlay p-5">
        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
      </div>
    </div>

    <!-- Add Blog -->
    <AddBlog :recordToUpdate="selectedRecordToUpdate" />

    <!-- NewsViewModal -->
    <div
      class="modal fade"
      id="viewNewsModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="viewNewsModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-scrollable modal-xl"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header p-2 border-none">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <NewsViewVue :newsDetails="viewNewsDetails" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import { generate } from "@vue/compiler-core";
import axios from "axios";
import moment from "moment";
import AddBlog from "../News/AddBlog.vue";
import NewsViewVue from "../News/NewsView.vue";
import $ from "jquery";

export default {
  name: "NewsAdminList",
  components: {
    AddBlog,
    NewsViewVue,
  },
  data() {
    return {
      eventInformation: null,
      userInfo: null,
      newsCollection: [],
      loading: true,
      viewNewsDetails: null,
      selectedRecordToUpdate: null,
      tableData: {
        dataBase: [],
        tableHeaders: null,
        title: "User Data",
      },
    };
  },
  created() {},
  mounted() {
    this.getDataBase();
  },
  methods: {
    viewNews(news) {
      this.viewNewsDetails = news;
    },
    countPostTime(dateTime) {
      return moment(dateTime).format("Do MMMM YYYY");
    },

    updateRecord(news) {
      this.selectedRecordToUpdate = Object.assign({}, news);
      $("#addBlogModal").modal("toggle");
    },

    refreshList() {
      this.getDataBase();
    },
    deleteEvent(eventId) {
      var _this = this;
      const reqBody = {
        BlogPKIds: eventId.toString(),
      };
      axios
        .post(window.location.origin + "/api/Blog/DeleteBlogs", reqBody, {
          withCredentials: true,
        })
        .then(function (resp) {
          _this.getDataBase();
          alert("Event Deleted.");
        })
        .catch(function (error) {
          if (error.response) {
            alert("Something went wrong.");
          } else {
            console.log(error);
          }
        });
    },
    handleEventInfoModal(eventInfo) {
      this.eventInformation = eventInfo;
    },
    handleEventregistrationInfoModal(eventInfo) {
      this.eventInformation = eventInfo;
    },
    getDataBase() {
      var _this = this;
      var requestBody = {};
      this.loading = true;
      axios
        .post("/api/Blog/GetBlogsList", requestBody)
        .then(function (response) {
          _this.loading = false;
          _this.newsCollection = response.data.blogCollectionItems;
        })
        .catch(function (error) {
          _this.loading = false;
          // handle error
          console.log(error);
        });
    },
  },
};
</script>
    
    <style>
.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}
</style>